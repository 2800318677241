<template>
    <div :class="`br-${borderRadius}`" class="progress text-center" :style="{ height: height + 'px' }">
        <div class="progress-bar"
             :class="`br-${borderRadius}`"
             :style="{ width: ((percentage <= 100 ) ? percentage :  '100') + '%', backgroundColor: color, color: textColor }">
            {{ (displayOpts.showTitle) ? title : '' }} {{ displayOpts.showPercentage ? percentage.toFixed(2) : '' }}% - {{ (financialFormat) ? `$${displayOpts.showValue ? value.toLocaleString() : ''} / $${ displayOpts.showMax ? max.toLocaleString() : '' }` : ` ${value} / ${max} ` }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 100,
        },
        value: {
            type: Number,
            default: 45,
        },
        title: {
            type: String,
            default: '',
        },
        height: {
            type: Number,
            default: 20,
        },
        width: {
            type: Number,
            default: 100,
        },
        color: {
            type: String,
            default: '#2196f3',
        },
        textColor: {
            type: String,
            default: '#fff',
        },
        financialFormat: {
            type: Boolean,
            default: true,
        },
        borderRadius: {
            type: Number,
            default: 0,
        },
        displayOpts: {
            type: Object,
            default: () => ({
                showTitle: true,
                showValue: true,
                showMax: true,
                showPercentage: true,
            }),
        },
        percentPrecision: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        percentage() {
            const value = Math.max(this.value, this.min);
            return ((value - this.min) / (this.max - this.min)) * 100;
        },
    },
};
</script>

<style scoped>
.progress {
    background-color: #b0b0b0;
    border-radius: 20px;
}

.progress-bar {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    padding: 0 5px;
    white-space: nowrap;
}
</style>
