<template>
    <div>
        <div v-if="active" class="loading-icon">
            <div class="spinner"></div>
            <span :class="msgClassList">{{ message }}</span>
        </div>
    </div>

</template>

<script>
export default {
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        message: {
            type: String,
            default: ''
        },
        msgClassList: {
            type: String,
            default: ''
        }
    },
};
</script>

<style>
.loading-icon {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.spinner {
    width: 50px;
    height: 50px;
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-top-color: #2196F3;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}
@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
</style>