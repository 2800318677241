<template>
    <div class="panel panel-default">
        <div class="panel-heading panel-title text-center">
            <h4>{{ sales.title }}</h4>
        </div>
        <div class="panel-body text-center">
            <div class="position_relative">
                <div class="donut_middle">{{ sales.data.percent }}%</div>
                <Chart v-bind:results="{chartData: this.sales.data.chart}" :options="{legend: {labels:{boxWidth: 20, padding: 5}}, maintainAspectRatio: false, tooltips: { callbacks: {label: function(tooltipItem, chart){
          var value = chart.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return '$' + value.toLocaleString('en-US');
        }}}}" containerClass="donut_chart_container" />
            </div>
            <div class="dashboard_lg_text"><span class="dashboard_separate">{{ sales.data.value }}</span></div>
            <div>{{ sales.data.goal }}</div>
        </div>
    </div>
</template>

<script>
import Chart from "@/components/utils/Chart";
export default {
    name: "Sales",
    props: ['sales'],
    components: {
        Chart
    },
}
</script>

<style scoped>

</style>